<template>
  <div class="DialogSign__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogSign__Component__Signature">
          <Signpad ref="signature" :background="stamp" @onChange="onChange" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          :disabled="isEmpty || isLoading"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />

        <a href="" @click.prevent="clear()">נקה חתימה</a>
        <div class="DialogSign__Component__Signature__Watermark">
          <b-upload
            v-if="!stamp"
            v-model="file"
            class="file-label"
            @input="onFileUpload"
          >
            <span class="file-cta">
              <span class="file-label">{{
                stamp ? "החלף חותמת" : "הוספת חותמת"
              }}</span>
            </span>
          </b-upload>
          <a @click.prevent="stamp = null" v-if="stamp" class="file-label"
            >הסר חותמת</a
          >
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import DriverService from "@/services/DriverService";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import Signpad from "./Signpad.vue";
import AccidentService from "../services/AccidentService";

export default {
  name: "DialogSign",
  props: ["reports", "onSuccess", "title", "signCase", "accidentId"],
  components: {
    Signpad,
  },
  created() {
    if (this.signCase === "leasingCompantLawyerSign") {
      DriverService.get().then((r) => {
        this.data = r;
      });
    }
  },
  data() {
    return {
      isLoading: false,
      file: {},
      stamp: null,
      isEmpty: true,
      options: {
        penColor: "#c0f",
      },
    };
  },
  methods: {
    onFileUpload(file) {
      const self = this;
      const reader = new FileReader();

      reader.onload = function (e) {
        self.stamp = e.target.result;
      };
      reader.onerror = function () {};
      reader.readAsDataURL(file);
    },
    clear() {
      this.$refs.signature.clear();
      this.isEmpty = true;
    },
    setSelected(value) {
      this.driverId = value;
    },
    async save() {
      const signature = await this.$refs.signature.save();
      this.isLoading = true;
      let call = null;
      if (this.signCase === "leasingCompantLawyerSign") {
        call = LeasingCompaniesService.lawyerSign({
          ReportIds: this.reports,
          SignatureImage: signature,
        });
      } else if (this.signCase === "driverAccidentSign") {
        const { data } = await AccidentService.getAccident(this.accidentId);
        call = AccidentService.driverSign(data, {
          SignatureImage: signature,
        });
      }
      call
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange() {
      this.isEmpty = this.$refs.signature.isEmpty();
    },
  },
};
</script>

<style lang="scss">
.DialogSign__Component {
  user-select: none;
}
.DialogSign__Component__Signature__Watermark {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 22px;
  display: flex;
  align-items: center;

  &:hover > span {
    background-color: #f1f1f1;
  }

  .file-cta {
    background-color: transparent;
    border: none;
  }
}
</style>
