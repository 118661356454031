var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogSign__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogSign__Component__Signature" },
          [
            _c("Signpad", {
              ref: "signature",
              attrs: { background: _vm.stamp },
              on: { onChange: _vm.onChange }
            })
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              disabled: _vm.isEmpty || _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          }),
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.clear()
                }
              }
            },
            [_vm._v("נקה חתימה")]
          ),
          _c(
            "div",
            { staticClass: "DialogSign__Component__Signature__Watermark" },
            [
              !_vm.stamp
                ? _c(
                    "b-upload",
                    {
                      staticClass: "file-label",
                      on: { input: _vm.onFileUpload },
                      model: {
                        value: _vm.file,
                        callback: function($$v) {
                          _vm.file = $$v
                        },
                        expression: "file"
                      }
                    },
                    [
                      _c("span", { staticClass: "file-cta" }, [
                        _c("span", { staticClass: "file-label" }, [
                          _vm._v(
                            _vm._s(_vm.stamp ? "החלף חותמת" : "הוספת חותמת")
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.stamp
                ? _c(
                    "a",
                    {
                      staticClass: "file-label",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.stamp = null
                        }
                      }
                    },
                    [_vm._v("הסר חותמת")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }