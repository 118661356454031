var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Signpad__Component" }, [
    _vm.background
      ? _c("img", {
          staticClass: "Signpad__Component__Background",
          attrs: { src: _vm.background, alt: "" }
        })
      : _vm._e(),
    _c("canvas", { ref: "canvas", attrs: { width: "400px", height: "200px" } }),
    _c("div", { staticClass: "Signpad__Component__Line" }, [
      _vm._v("חתום בתיבה מעל הקו")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }