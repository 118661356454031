<template>
  <div class="Signpad__Component">
    <img
      class="Signpad__Component__Background"
      v-if="background"
      :src="background"
      alt=""
    />
    <canvas ref="canvas" width="400px" height="200px"></canvas>
    <div class="Signpad__Component__Line">חתום בתיבה מעל הקו</div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  name: "Signpad",
  props: ["background"],
  data() {
    return {
      signaturePad: null,
    };
  },
  mounted() {
    const { canvas } = this.$refs;
    this.signaturePad = new SignaturePad(canvas, {
      penColor: "#0143C1",
      onEnd: () => {
        this.$emit("onChange");
      },
    });

    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  methods: {
    clear() {
      this.signaturePad.clear();
    },
    resizeCanvas() {
      const { canvas } = this.$refs;
      // const ratio = Math.max(window.devicePixelRatio || 1, 1); //not required because window.devicePixelRatio doesnt matter- the signpad is in a modal! therefor resize isnt nessesery at all- canvas size stayes permenent, modal changes Responsively
      const ratio = 1;
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      this.clear(); // otherwise isEmpty() might return incorrect value
    },
    createImage() {
      const self = this;
      const data = this.data();
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        canvas.width = 400;
        canvas.height = 200;
        const ctx = canvas.getContext("2d");
        const imageObj1 = new Image();
        const imageObj2 = new Image();
        if (self.background) {
          imageObj1.src = self.background;
          imageObj1.onload = function () {
            ctx.drawImage(imageObj1, 400 - this.width, 0);
            document.body.append(canvas);
            imageObj2.src = data;
            imageObj2.onload = function () {
              ctx.drawImage(imageObj2, 0, 0);
              const img = canvas.toDataURL("image/png");
              resolve(img);
            };
          };
        } else {
          imageObj2.src = data;
          imageObj2.onload = function () {
            ctx.drawImage(imageObj2, 0, 0);
            const img = canvas.toDataURL("image/png");
            resolve(img);
          };
        }
      });
    },
    data() {
      return this.signaturePad.toDataURL();
    },
    isEmpty() {
      return this.signaturePad.isEmpty();
    },
    save() {
      return this.createImage();
    },
  },
};
</script>

<style scoped lang="scss">
.Signpad__Component {
  position: relative;
  height: 200px;
  width: 400px;
  margin: 0 auto;
  overflow: hidden;

  .Signpad__Component__Background {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;
    user-select: none;
    max-width: unset;
  }
  canvas {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    border: 1px solid #eaeaea;
  }
  .Signpad__Component__Line {
    position: absolute;
    height: 1px;
    width: 90%;
    bottom: 15%;
    left: 5%;
    background-color: black;
    text-align: center;
    pointer-events: none;
    z-index: 3;
    user-select: none;
  }
}
</style>
